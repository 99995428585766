'use client'

import { Slot } from '@radix-ui/react-slot'
import { motion } from 'framer-motion'
import { type Dispatch, type SetStateAction, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export default function BasicCarousel({
  children,
  currentPage,
  setCurrentPage,
  totalPages,
  fadeEffect,
}: {
  children: React.ReactNode
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  totalPages: number
  fadeEffect?: boolean
}) {
  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)
  const [isDragging, setIsDragging] = useState(false)

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true)
    setTouchEnd(null)
    setTouchStart(e.clientX)
  }

  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const onMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      setTouchEnd(e.clientX)
    }
  }

  const onTouchEnd = () => {
    handleSwipeEnd()
  }

  const onMouseUp = () => {
    if (isDragging) {
      setIsDragging(false)
      handleSwipeEnd()
    }
  }

  const handleSwipeEnd = () => {
    if (!touchStart || !touchEnd) return

    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance

    if (isLeftSwipe && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1)
    }
    if (isRightSwipe && currentPage > 1) {
      setCurrentPage((prev) => prev - 1)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, translateX: -20 }}
      animate={{ opacity: 1, translateX: 0 }}
      transition={{ duration: 0.5 }}
      className={twMerge('relative w-full', fadeEffect && 'overflow-hidden')}
    >
      {/* fade effect on the right */}
      {fadeEffect && (
        <div className="absolute right-0 top-0 h-full w-[10%] z-50 bg-gradient-to-r from-transparent to-parchement" />
      )}
      <div
        className={
          'flex transition-transform duration-500 ease-in-out touch-pan-y cursor-grab active:cursor-grabbing [&>*]:w-[95%]'
        }
        style={{
          transform: `translateX(-${Math.min(currentPage - 1, totalPages - 1) * 95}%)`,
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
      >
        {children}
      </div>
    </motion.div>
  )
}

export function CarouselItem({ children, className }: { children: React.ReactNode; className?: string }) {
  return <Slot className={twMerge('flex-shrink-0 select-none', className)}>{children}</Slot>
}
