'use client'

import Header from '@/components/layout/Header'
import Hero from './Hero'

export default function HeaderHero() {
  return (
    <>
      <Header enableResize />
      <Hero />
    </>
  )
}
