import { type VariantProps, cva } from 'class-variance-authority'
import type * as React from 'react'

import { cn } from '@/lib/utils'

const badgeVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-[16px] font-sourcecodepro font-normal leading-[20.11px] text-center transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 w-fit',
  {
    variants: {
      variant: {
        default: 'bg-[var(--secondary-bg)] border border-primary text-primary text-[13px]',
        'button-current':
          'border-[var(--secondary-hover-border)] text-[var(--secondary-hover-text)] bg-[var(--secondary-active-bg)]',
        'button-loading': 'pointer-events-none bg-slate-200 text-slate-400 border border-slate-200',
        filled: 'bg-[var(--primary-bg)] text-[var(--primary-text)] border border-transparent',
      },
      size: {
        default: 'rounded-md px-[6px] py-0.5',
        large: 'h-[52px] gap-2 px-3 rounded-sm text-[16px]',
        medium: 'h-[44px] gap-2 px-3 rounded-sm text-[15px]',
        small: 'h-[32px] gap-1 px-3 rounded-sm text-[14px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  theme?: 'light' | 'dark'
}

function Badge({ className, variant, size, theme = 'light', ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, size }), theme, className)} {...props} />
}

export { Badge, badgeVariants }
