'use client'
import StatsCards from '@/components/StatsCards'
import {} from 'framer-motion'

const metrics = [
  {
    value: 25,
    unit: '%',
    description: 'Reduction in overtime',
    image: '/assets/home/overtime-yearly-graphic.png',
  },
  {
    value: 70,
    unit: '%',
    description: 'Employees feel less stressed',
    image: '/assets/home/wellbeing-index-graphic.png',
  },
  {
    value: 8,
    unit: ' hours',
    description: 'More free time per week',
    image: '/assets/home/message-center-graphic.png',
  },
  {
    value: 50,
    unit: '%',
    description: 'Reduction in work admin',
    image: '/assets/home/cost-reduction-graphic.png',
  },
]

const KeyMetrics = () => {
  return (
    <section id="key-metrics" className="container">
      <StatsCards stats={metrics} />
    </section>
  )
}

export default KeyMetrics
