'use client'

import { animate, motion, useMotionValue, useTransform } from 'framer-motion'
import Image from 'next/image'
import { useEffect } from 'react'
import { Heading } from './ui/Heading'
import Paragraph from './ui/Paragraph'

type StatCardProps = {
  image: string
  value: number
  unit: string
  description: string
}

const StatCard = ({ image, value, unit, description }: StatCardProps) => {
  const count = useMotionValue(0)
  const displayValue = useTransform(count, (latest) => `${Math.round(latest)}${unit}`)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          animate(count, value, {
            duration: 3,
            ease: 'easeOut',
          })
        }
      },
      { threshold: 0.1 },
    )

    const element = document.getElementById(`stat-${value}${unit}`)
    if (element) {
      observer.observe(element)
    }

    return () => observer.disconnect()
  }, [count, value, unit])

  return (
    <div id={`stat-${value}${unit}`} className="bg-white rounded-md flex flex-col h-auto md:h-[340px] w-full">
      <div className="relative h-48 md:h-[55%]">
        <Image src={image} alt="" quality={100} fill className="object-cover object-top rounded-t-[8px] bg-gray-200" />
      </div>
      <div className="p-4 flex flex-col gap-2 justify-center">
        <Heading level="h2">
          <motion.span>{displayValue}</motion.span>
        </Heading>
        <Paragraph>{description}</Paragraph>
      </div>
    </div>
  )
}

type StatsCardsProps = {
  stats: StatCardProps[]
}

const StatsCards = ({ stats }: StatsCardsProps) => {
  return (
    <div
      className={`flex flex-col gap-4 md:grid md:grid-cols-2 ${stats.length === 4 ? 'lg:grid-cols-4' : 'lg:grid-cols-3'} justify-items-center mx-auto max-w-[1200px]`}
    >
      {stats.map((stat) => (
        <div key={`${stat.value}${stat.unit}`} className="w-3/4 odd:self-start even:self-end md:w-full md:self-auto">
          <StatCard {...stat} />
        </div>
      ))}
    </div>
  )
}

export default StatsCards
