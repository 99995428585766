'use client'
import { Heading } from '@/components/ui/Heading'
import Image from 'next/image'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import HeroAnimations from './components/HeroAnimations'

const Hero = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <section
      id="hero"
      ref={ref}
      className="relative w-full -mt-16 bg-[#F9F4E4] pt-12 md:pt-28 md:mt-[-60px] md:px-6 lg:pb-0 overflow-hidden md:bg-hero bg-no-repeat bg-cover bg-center"
    >
      <div className="mx-auto flex flex-col gap-5 md:gap-10 lg:gap-20  my-16 max-w-6xl justify-center items-center">
        <div className="flex flex-col gap-10 justify-center items-center">
          <Heading level="h1" className={twMerge('text-center max-w-3xl')}>
            Digital Replicas <br />
            that work 24/7, <br />
            so you don't have to
          </Heading>
        </div>

        <div className="relative w-full">
          <div className="relative w-full max-w-[860px] mx-auto p-6">
            <div className="w-full relative" style={{ aspectRatio: '5/4' }}>
              <Image
                src="/assets/home/hero-dashboard-mockup.jpg"
                alt="Dashboard interface"
                fill
                className="object-cover rounded-md bg-burgundy"
                priority
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 90vw, 1200px"
                quality={85}
              />
            </div>
          </div>

          <div className="absolute inset-0 w-full h-full overflow-visible z-10">
            <div className="relative w-full h-full mx-auto overflow-visible">
              <HeroAnimations />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Hero
