import { Badge } from '@/components/ui/Badge'
import { cn } from '@/lib/utils'

const PlanTag = ({ className, title }: { className: string; title: string }) => {
  return (
    <Badge
      size="small"
      variant="filled"
      className={cn(
        'flex items-center w-fit py-[2px] px-[12px] gap-[8px] rounded-sm font-sourcecodepro text-black hover:text-inherit',
        className,
      )}
    >
      <div className="bg-burgundy h-[4px] w-[4px]" />
      {title}
    </Badge>
  )
}

export default PlanTag
